// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Y5j0dDhSW9QZIpdhXXVfe {\n    background-color: #62A5BA;\n}", ""]);
// Exports
exports.locals = {
	"main": "_3Y5j0dDhSW9QZIpdhXXVfe"
};
module.exports = exports;
