// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3wjl61bpks-IKacAv_B2QM{ \n  display: flex;\n  flex-basis: 12%;\n  height: 100%;\n  margin-right: 20px;\n}\n\n._2M9zgigHm-PpvvBfwdWiGL {\n  display: flex;\n  flex-flow: column;\n  justify-content: flex-end;\n  align-items: center;\n  color: white;\n}\n\n._2M9zgigHm-PpvvBfwdWiGL h5 {\n  font-size: 1.25rem;\n}", ""]);
// Exports
exports.locals = {
	"scoreContainer": "_3wjl61bpks-IKacAv_B2QM",
	"scoreText": "_2M9zgigHm-PpvvBfwdWiGL"
};
module.exports = exports;
