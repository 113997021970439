// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1b3ll1S6oSSFhGHc4LE2Mm{\n    display: flex;\n    flex-flow: column;\n    justify-content: flex-start;\n    align-items: center;\n    margin-top: 10%;\n    padding: 2%;\n    height: 100vh;\n}\n\n._3oTlj5HDeLily0QyI_Qao1{\n    display: flex;\n    flex-flow: column;\n    width: 100%;\n    justify-content: center;\n    align-items: center;\n}\n\n._1b3ll1S6oSSFhGHc4LE2Mm h1{\n    display: flex;\n    padding: 5%;\n}\n\n._1WQ1kApQDID3PlwVD0-iTX {\n    width: 26%;\n    display: flex;\n    max-width: 400px;\n}\n\n@media only screen and (max-width: 750px) {\n  ._1WQ1kApQDID3PlwVD0-iTX {\n    width: 40%;\n  }\n}", ""]);
// Exports
exports.locals = {
	"container": "_1b3ll1S6oSSFhGHc4LE2Mm",
	"text": "_3oTlj5HDeLily0QyI_Qao1",
	"image": "_1WQ1kApQDID3PlwVD0-iTX"
};
module.exports = exports;
