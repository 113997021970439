// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1j7EHw-YM96O0vwpem4FWY {\n    flex-flow: column;\n    align-items: center;\n    display: flex;\n    background-color: #62A5BA!important;\n}\n._2m23H3oG5XT996p7atNhen {\n    display: flex;\n    width: 75%;\n    flex-flow: column;\n}\n\n._1dK4AeKUUiQ5IhEnvbrI_d {\n      display: flex;\n  flex-flow: row;\n  align-items: center;\n  flex-basis: 35%;\n  justify-content: center;\n  padding: 2%;\n}\n\n._17WyBPEa3swRcxDzYHK0eb {\n    width: 4vh;\n    height: 4vh;\n}\n\n._3VzRvx6R5qjv4qI47ix-NS {\n    width: 200px;\n    height: 200px;\n}\n\n._2wrO-8kF-d9rKP9Fx5_Em {\ndisplay: flex;\nflex-direction: row;\nflex-wrap: nowrap;\n}\n\n._36FWklA2u0yvl2jNWyZ_JL {\n    display: flex;\n    flex-shrink: .5;\n}\n\n@media only screen and (max-width: 1250px) {\n    ._2wrO-8kF-d9rKP9Fx5_Em {\n    flex-direction: column;\n    height: 700px;\n    }\n    ._3-Kl5tm4EOBcykCqM7P2Q8 {\n        flex-basis: auto;\n    }\n    ._36FWklA2u0yvl2jNWyZ_JL {\n      flex-basis: 65%;\n      flex-shrink: 1;\n    }\n}\n\n@media only screen and (min-width: 1250px) {\n  ._3-Kl5tm4EOBcykCqM7P2Q8 {\n      flex-basis: auto;\n  }\n  ._36FWklA2u0yvl2jNWyZ_JL {\n      flex-basis: 70%;\n  }\n  ._2wrO-8kF-d9rKP9Fx5_Em {\n      height: 500px;\n  }\n}\n\n@media only screen and (max-width: 550px) {\n    ._2wrO-8kF-d9rKP9Fx5_Em {\n        flex-direction: column;\n    }\n    ._3-Kl5tm4EOBcykCqM7P2Q8 {\n      flex-basis: auto;\n    }\n    ._36FWklA2u0yvl2jNWyZ_JL {\n        padding-bottom: 10px;\n        flex-shrink: 1;\n    }   \n}\n\n\n._3-Kl5tm4EOBcykCqM7P2Q8 {\ndisplay: flex;\nflex-flow: column;\nflex-grow: 0;\nflex-shrink: 2;\npadding-right: 5px;\n}\n\n\n", ""]);
// Exports
exports.locals = {
	"rulesJumbotron": "_1j7EHw-YM96O0vwpem4FWY",
	"rulesContainer": "_2m23H3oG5XT996p7atNhen",
	"container": "_1dK4AeKUUiQ5IhEnvbrI_d",
	"cats": "_17WyBPEa3swRcxDzYHK0eb",
	"ruleImage1": "_3VzRvx6R5qjv4qI47ix-NS",
	"videoBasicElementsContainer": "_2wrO-8kF-d9rKP9Fx5_Em",
	"video": "_36FWklA2u0yvl2jNWyZ_JL",
	"basicElementsContainer": "_3-Kl5tm4EOBcykCqM7P2Q8"
};
module.exports = exports;
