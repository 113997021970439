// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pdAwniJW9tPTodS_E7zz6 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  border-left: solid #dfdfdf 1px;\n  border-top: solid #dfdfdf 1px;\n  font-family: 'Ubuntu', sans-serif;\n  color: #90a2a7;\n  font-weight: 100;\n  font-size: 13px;\n  background-color: #fff;\n}\n\n.pdAwniJW9tPTodS_E7zz6:nth-child(9n) {\n  border-right: solid #dfdfdf 1px;\n}\n\n.pdAwniJW9tPTodS_E7zz6 p{\n  padding: 0;\n  margin: 0;\n  text-align: center;\n  color: black;\n  font-size: 4vh;\n}\n\n.pdAwniJW9tPTodS_E7zz6:nth-last-child(-n+9) {\n  border-bottom: solid #dfdfdf 1px;\n}", ""]);
// Exports
exports.locals = {
	"square": "pdAwniJW9tPTodS_E7zz6"
};
module.exports = exports;
