// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._37xdOnFfv24N6J7aYORaqw {\n  display: grid;\n  grid-template-columns: repeat(9, minmax(45px, 9vmin));\n  grid-template-rows: repeat(9, minmax(45px, 9vmin));\n}\n\n._37xdOnFfv24N6J7aYORaqw div:first-child {\n  border-top-left-radius: 12px;\n}\n._37xdOnFfv24N6J7aYORaqw div:nth-child(9) {\n  border-top-right-radius: 12px;\n}\n\n._37xdOnFfv24N6J7aYORaqw div:last-child {\n  border-bottom-right-radius: 12px;\n}\n\n._37xdOnFfv24N6J7aYORaqw div:nth-child(73) {\n  border-bottom-left-radius: 12px;\n}\n\n._2H1HDrD4V7h1iMBKaFJXYi{ \n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  flex-basis: 35%;\n  justify-content: flex-start;\n  padding: 2%;\n  height: 100vh;\n}\n\n@media only screen and (max-width: 1000px) {\n  ._2H1HDrD4V7h1iMBKaFJXYi {\n  flex-flow: column;\n  }\n}\n\n._2H1HDrD4V7h1iMBKaFJXYi:first-child {\n  border-radius: 12px;\n}\n\n._1EQQP7kWNqYun3f_MFdpk_ {\n  display: flex;\n  flex-flow: row;\n  height: 85px;\n  align-items: flex-end;\n  justify-content: center;\n  margin-bottom: 5px;\n}\n._1EQQP7kWNqYun3f_MFdpk_ {\n  margin-left: 10px;\n  margin-right: 10px;\n  display: flex;\n}\n\n._1fxdTy4Qb7S2Rej9xE3yzK {\n  display: flex;\n  height: 50vh;\n  width: 90%;\n  margin: 0px;\n  position: absolute;\n  background-color: #62A5BA;\n  flex-flow: column;\n  justify-content: center;\n  align-items: center;\n}\n\n._2Kn-jjPphvkB8eCw-PlsJ7 {\n  font-size: 30px;\n  color: red;\n}\n\n@media only screen and (max-width: 1000px) {\n  ._1EQQP7kWNqYun3f_MFdpk_ {\n    width: 100%;\n  }\n}\n\n@media only screen and (max-width: 600px) {\n  ._37xdOnFfv24N6J7aYORaqw {\n    display: grid;\n    grid-template-columns: repeat(9, minmax(10vmin, 10vmin));\n    grid-template-rows: repeat(9, minmax(10vmin, 10vmin));\n  }\n}", ""]);
// Exports
exports.locals = {
	"board": "_37xdOnFfv24N6J7aYORaqw",
	"boardContainer": "_2H1HDrD4V7h1iMBKaFJXYi",
	"nextAndScore": "_1EQQP7kWNqYun3f_MFdpk_",
	"gameOverContainer": "_1fxdTy4Qb7S2Rej9xE3yzK",
	"gameOverText": "_2Kn-jjPphvkB8eCw-PlsJ7"
};
module.exports = exports;
