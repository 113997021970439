// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Lzh3Eys1Ndi522-cCELwp {\n    display: flex;\n    flex-flow: row;\n    align-items: center;\n    background: rgba(255, 255, 255, 0.637);\n    padding: 1px;\n    border-radius: 12px;\n    margin-bottom: 10px;\n    margin-left: 20px;\n}\n\n._1Lzh3Eys1Ndi522-cCELwp {\n    opacity: 1;\n\tanimation-name: WJorJ8N73tD-IY1GxW0yO;\n\tanimation-iteration-count: 1;\n\tanimation-timing-function: ease-in;\n\tanimation-duration: 1s;\n}\n\n._17BfP38-E8BCcib7uKDPhe {\n    opacity: 1;\n\tanimation-name: WJorJ8N73tD-IY1GxW0yO;\n\tanimation-iteration-count: 1;\n\tanimation-timing-function: ease-in;\n    animation-duration: .3s;\n    width: 7vh;\n    height: 7vh;\n    min-height: 34px;\n    min-width: 34px;\n    max-width: 69px;\n    max-height: 69px;\n}\n\n@keyframes WJorJ8N73tD-IY1GxW0yO {\n    0% {\n        opacity: 0;\n    }\n    100% {\n        opacity: 1;\n    }\n}", ""]);
// Exports
exports.locals = {
	"next": "_1Lzh3Eys1Ndi522-cCELwp",
	"fadeInOpacity": "WJorJ8N73tD-IY1GxW0yO",
	"cat": "_17BfP38-E8BCcib7uKDPhe"
};
module.exports = exports;
