// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1SEl3f0B3G7BWhQDBkMOPz {\n  width: 7vmin;\n  height: 7vmin;\n  min-height: 34px;\n  min-width: 34px;\n  max-width: 69px;\n  max-height: 69px;\n}\n\n.U576ZKXO00u2kbV-TT_tV {\n  width: 6vh;\n  height: 5vh;\n  max-width: 69px;\n  max-height: 69px;\n  position: absolute;\n}", ""]);
// Exports
exports.locals = {
	"image": "_1SEl3f0B3G7BWhQDBkMOPz",
	"dragSource": "U576ZKXO00u2kbV-TT_tV"
};
module.exports = exports;
