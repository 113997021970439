// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2eRpWmEQaCdCRbWt2XMG7U {\n  padding: 20px;\n  display: flex;\n  justify-content: center;\n}\n\n.MMu8DTe-QOuaIHK3p39RE {\n  display: flex;\n  flex-flow: column;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 2%;\n  height: 100vh;\n}", ""]);
// Exports
exports.locals = {
	"mainContainer": "_2eRpWmEQaCdCRbWt2XMG7U",
	"leaderboardPage": "MMu8DTe-QOuaIHK3p39RE"
};
module.exports = exports;
